import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import redphone from '../assets/images/redphone.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Contact Information</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Contact Information</h1>
                    </header>
                    <p>I am one person, and I am a student. If my schedule becomes too full, I may decline potential tutees.</p>
                    <p>The button below will lead you to a google form asking for a bit of information about your needs. Please fill out the form before attempting to reach me about scheduling sessions. </p>
                    <p>It's fine that the information in the form may change, and if you're not sure, just put in your best guess. The form is simply a way of making sure I operate on first-come, first-serve basis. I will likely respond to your form within 48 hours by email, either saying I have no room or asking for a schedule. Make sure to check your email for my reply! If you have any questions, send me an email, and I will get back to you ASAP.</p>
                    <ul className="actions vertical">
                        <li><a href="https://forms.gle/QKN4U996DsyA9UDdA" target="_blank" className="button special fit">Schedule a Session</a></li>
                    </ul>
                    <p>Email: derivingsuccess@gmail.com </p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
